import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Helmet from "react-helmet"

const ServicesListing = () => {
  const data = useStaticQuery(graphql`
    query {
      services: allSanityServices {
        edges {
          node {
            title
            service_image {
              asset {
                url
              }
            }
            slug {
              current
            }
          }
        }
      }
    }
  `)

  const { services } = data
  const allServices = services.edges

  return (
    <Layout>
      <SEO title="Our Services" />
      <Helmet
        bodyAttributes={{
          class: "bg-power-lines",
        }}
      />
      <div className="container mx-auto py-24">
        <h1 className="mx-4 md:mx-0 uppercase font-bold tracking-wide mb-8 text-5xl border-b border-gray-800">
          Our Services
        </h1>
        <div className="px-4 md:px-0 flex flex-wrap md:-mx-4">
          {allServices.map((s, idx) => {
            let service = s.node
            return (
              <div className="w-full md:w-1/3" key={idx}>
                <div className="m-4 bg-red-500">
                  <Link
                    to={`/services/${service.slug.current}`}
                    className="block duotone-thumb relative"
                  >
                    <span className="block bottom-0 absolute p-8 font-black text-4xl md:text-5xl leading-tight text-shadow-md">
                      {service.title}
                    </span>
                    <img
                      src={`${service.service_image.asset.url}?w=600&h=600&fit=crop&sat=-100`}
                      alt={`${service.title} Listing`}
                      className="shadow-md"
                    />
                  </Link>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default ServicesListing
